import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  Checkbox,
  NumberInput,
  Select,
  TextInput
} from '@thedealersconcierge/components';
import { inputBackgroundTypes } from '@thedealersconcierge/components/src/input/inputConfig';
import {
  AddressHousingStatusType,
  VehicleRegistrationAddressAnswer
} from '@thedealersconcierge/lib/codecs/tdc';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { literal, z } from 'zod';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import FormContainer from './FormContainer';

export type AddressFormValues = {
  vehicleRegistrationAddressAnswer: string;
  street: string;
  apartmentDetails: string;
  city: string;
  state: string;
  zipCode: string;
  housingStatus: string;
  monthlyPayment: string;
  durationYears: string;
  durationMonths: string;
};

export const ValidAddressSchema = (
  t: TFunction,
  type: 'CURRENT' | 'PREVIOUS',
  environment: 'WEB' | 'IN_APP'
) =>
  z.object({
    vehicleRegistrationAddressAnswer:
      environment === 'IN_APP' && type === 'CURRENT'
        ? VehicleRegistrationAddressAnswer
        : z.literal(''),
    street: z.string().min(1, t('This field is required')),
    apartmentDetails: z.string(),
    city: z.string().min(1, t('This field is required')),
    state: z.string().min(1, t('This field is required')),
    zipCode: z.string().min(1, t('This field is required')),
    housingStatus:
      type === 'CURRENT'
        ? AddressHousingStatusType
        : AddressHousingStatusType.or(z.literal('')),
    monthlyPayment: z
      .string()
      .min(type === 'CURRENT' ? 1 : 0, t('This field is required')),
    durationYears: z.string(),
    durationMonths: z.string()
  });

const AddressForm: FC<{
  form: ReactFormApi<AddressFormValues, ZodValidator>;
  isSubmitting: boolean;
  type: 'CURRENT' | 'PREVIOUS';
  className?: string;
  dataTestId?: string;
  environment: 'WEB' | 'IN_APP';
  onAnsweringRegistrationAsDifferent?: () => void;
}> = ({
  form,
  isSubmitting,
  type,
  className,
  dataTestId,
  environment,
  onAnsweringRegistrationAsDifferent
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {environment === 'IN_APP' && type === 'CURRENT' && (
        <div
          className={classNames(
            'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
            'p-spacing-05 bg-interactive-secondary space-y-spacing-04'
          )}
        >
          <p>
            {t(
              'Are You Planning To Register Your Vehicle at the Address Below?'
            )}
          </p>

          <form.Field name="vehicleRegistrationAddressAnswer">
            {(field) => {
              return (
                <div className="flex flex-row space-x-spacing-06">
                  <div
                    className="flex flex-row space-x-spacing-02 cursor-pointer"
                    onClick={() => {
                      field.handleChange('SAME');
                    }}
                    data-test-id={`${dataTestId}-vehicle-registration-address-answer-yes`}
                  >
                    {/**
                     * TODO: Implement radio button variant
                     */}
                    <Checkbox
                      checked={field.state.value === 'SAME'}
                      onChange={() => {}}
                      disabled={isSubmitting}
                      size="SMALL"
                    />

                    <p>{t('Yes')}</p>
                  </div>

                  <div
                    className="flex flex-row space-x-spacing-02 cursor-pointer"
                    onClick={() => {
                      field.handleChange('DIFFERENT');
                      onAnsweringRegistrationAsDifferent?.();
                    }}
                  >
                    {/**
                     * TODO: Implement radio button variant
                     */}
                    <Checkbox
                      checked={field.state.value === 'DIFFERENT'}
                      onChange={() => {}}
                      disabled={isSubmitting}
                      size="SMALL"
                    />

                    <p>{t('No')}</p>
                  </div>
                </div>
              );
            }}
          </form.Field>
        </div>
      )}

      <FormContainer
        title={
          type === 'CURRENT'
            ? t('Current Address Information')
            : t('Address Information')
        }
        className={className}
        dataTestId={dataTestId}
      >
        <Fragment>
          <form.Field
            name="street"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Street Address')}
                  placeholder={t('Street Address')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 desktop:col-span-5"
                  dataTestId={`${dataTestId}-street`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="apartmentDetails"
            validators={{
              onBlur: z.string()
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Apartment Details')}
                  placeholder={t('Apartment Details')}
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-apartment-details`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="city"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('City')}
                  placeholder={t('City')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-city`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="state"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <Select
                  value={field.state.value}
                  label={t('State')}
                  placeholder={t('State')}
                  required
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(option.value);
                  }}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2"
                  options={stateOptions}
                  dataTestId={`${dataTestId}-state`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="zipCode"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('ZIP Code')}
                  placeholder={t('ZIP Code')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-zip-code`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="housingStatus"
            validators={{
              onBlur:
                type === 'CURRENT'
                  ? AddressHousingStatusType
                  : AddressHousingStatusType.or(literal(''))
            }}
          >
            {(field) => {
              return (
                <Select
                  value={field.state.value}
                  label={t('Housing Status')}
                  placeholder={t('Housing Status')}
                  required
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(
                      AddressHousingStatusType.parse(option.value)
                    );
                  }}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className={classNames(
                    'col-span-4 tablet:col-span-3 desktop:col-span-4',
                    {
                      hidden: type === 'PREVIOUS'
                    }
                  )}
                  options={housingStatusOptions(t)}
                  dataTestId={`${dataTestId}-housing-status`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="monthlyPayment"
            validators={{
              onBlur: z
                .string()
                .min(type === 'CURRENT' ? 1 : 0, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <NumberInput
                  value={field.state.value}
                  label={t('Monthly Payment')}
                  placeholder={t('Monthly Payment')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className={classNames(
                    'col-span-4 tablet:col-span-3 desktop:col-span-4',
                    {
                      hidden: type === 'PREVIOUS'
                    }
                  )}
                  prefix="$"
                  decimalScale={2}
                  dataTestId={`${dataTestId}-monthly-payment`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="durationYears"
            validators={{
              onBlur: z.string()
            }}
          >
            {(field) => {
              return (
                <NumberInput
                  value={field.state.value}
                  label={t('Number of Years at this Address')}
                  placeholder={t('Years at this Address')}
                  required={true}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  decimalScale={0}
                  dataTestId={`${dataTestId}-duration-years`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="durationMonths"
            validators={{
              onBlur: z.string()
            }}
          >
            {(field) => {
              return (
                <NumberInput
                  value={field.state.value}
                  label={t('Number of Months at this Address')}
                  placeholder={t('Months at this Address')}
                  required={true}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  decimalScale={0}
                  dataTestId={`${dataTestId}-duration-months`}
                />
              );
            }}
          </form.Field>
        </Fragment>
      </FormContainer>
    </Fragment>
  );
};

export default AddressForm;
