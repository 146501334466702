import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import Button from '~/components/Button';
import SignatureIcon from '../icons/SignatureIcon';

type Props = {
  className?: string;
  onSignatureSubmit: (signature: string) => Promise<void> | void;
  existingSignature?: null | string;
  isLoading?: boolean;
  disabled?: boolean;
  dataTestIdOpenModal?: string;
  dataTestIdCanvas?: string;
  dataTestIdSubmit?: string;
};

export default function Signature({
  className,
  onSignatureSubmit,
  existingSignature,
  isLoading,
  disabled,
  dataTestIdOpenModal,
  dataTestIdCanvas,
  dataTestIdSubmit
}: Props) {
  const { t } = useTranslation();
  // const signautreRef = useRef<SignatureCanvas>(null);
  const [signautreRef, setSignautreRef] = useState<SignatureCanvas | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleClose = () => {};
  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      signautreRef?.off();
      const signatureDataUrl = signautreRef?.toDataURL('image/png');

      if (!signatureDataUrl) {
        throw new Error('No signature found');
      }

      await onSignatureSubmit(signatureDataUrl);
      setIsOpen(false);
    } catch (e) {
      throw e;
    } finally {
      setIsSubmitting(false);
      signautreRef?.on();
    }
  }, [signautreRef]);
  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsOpen(true);
    }
  }, [disabled]);
  const handleEnd = () => {
    setIsEmpty(!!signautreRef?.isEmpty());
  };

  return (
    <>
      <div
        data-test-id={dataTestIdOpenModal}
        className={classNames(
          className,
          'flex w-full h-16 border-b items-center justify-end bg-tertiary rounded px-4',
          {
            'hover:cursor-pointer': !existingSignature && !disabled
          }
        )}
        onClick={handleClick}
      >
        {existingSignature && (
          <div className="relative w-full justify-start">
            <img src={existingSignature} className="h-16 object-contain" />
          </div>
        )}

        {!disabled && (
          <div className="flex flex-col space-y-1 p-1 bg-interactive-primary rounded w-10 aspect-square items-center justify-center">
            <div className="relative">
              <SignatureIcon className="w-5 text-white" />
            </div>

            <div className="text-[10px] text-white leading-none">Sign</div>
          </div>
        )}
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div
          className="z-30 flex absolute top-0 right-0 bottom-0 left-0 justify-center items-center px-4 md:px-8 bg-black bg-opacity-25"
          onClick={() => {
            if (!isSubmitting) {
              handleClose();
            }
          }}
          data-test-id={'signature-modal'}
        >
          <div
            className="z-20 flex flex-col bg-white p-4 rounded-2xl w-full tablet:w-1/2 desktop:w-1/3 space-y-6 justify-center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SignatureCanvas
              ref={(ref) => {
                setSignautreRef(ref);
                if (dataTestIdCanvas) {
                  ref
                    ?.getCanvas()
                    ?.setAttribute('data-test-id', dataTestIdCanvas);
                }
              }}
              // We don't set background as it needs to be transparent
              penColor="black"
              minWidth={1}
              canvasProps={{
                className:
                  'sigCanvas border rounded-2xl tablet:h-[360px] desktop:h-[400px] mobile:h-[200px]'
              }}
              onEnd={handleEnd}
            />

            <div className="flex justify-evenly">
              <Button
                variant="TERTIARY"
                onClick={() => setIsOpen(false)}
                disabled={isSubmitting}
                className="min-w-32"
              >
                {t('Cancel')}
              </Button>

              <Button
                dataTestId={dataTestIdSubmit}
                onClick={handleSubmit}
                loading={isSubmitting || isLoading}
                className="min-w-32"
                disabled={isEmpty}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
