import { FormSubmissionDataSchema } from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import { CustomerType } from '~/querySelectors/customer';
import { DealershipType } from '~/querySelectors/dealership';

const generateHardCreditApplicationFormSubmissionDataAction = ({
  customer,
  dealership,
  signature,
  ipAddress,
  deviceId,
  hasAcceptedAcknowledgements,
  hasAcceptedElectronicDisclosure,
  hasAcceptedModificationNotice,
  t,
  signatureName
}: {
  customer: CustomerType;
  dealership: DealershipType;
  signature?: string | undefined; // When we generate modified credit applications, we do not require a signature
  ipAddress: string;
  deviceId: string;
  hasAcceptedAcknowledgements?: boolean;
  hasAcceptedElectronicDisclosure?: boolean;
  hasAcceptedModificationNotice?: boolean;
  t: TFunction;
  signatureName?: string; // When we generate modified credit applications, we do not require a signature name
}): Extract<
  FormSubmissionDataSchema,
  { submissionType: 'HARD_CREDIT_APPLICATION' }
> => {
  const addresses = customer.residentialAddresses?.edges?.map((e) => e.node);
  const employments = customer.employments?.edges?.map((e) => e.node);
  const currentAddress = addresses?.find((a) => a?.timelinePosition === 0);
  const previousAddress = addresses?.find((a) => a?.timelinePosition === 1);
  const currentEmployment = employments?.find((e) => e?.timelinePosition === 0);
  const previousEmployment = employments?.find(
    (e) => e?.timelinePosition === 1
  );

  return {
    submissionType: 'HARD_CREDIT_APPLICATION',
    submissionData: {
      firstName: customer.firstName ?? '',
      middleName: customer.middleName ?? '',
      lastName: customer.lastName ?? '',
      streetAddress: currentAddress?.street ?? '',
      city: currentAddress?.city ?? '',
      state: currentAddress?.state ?? '',
      zip: currentAddress?.zipCode ?? '',
      birthdate: customer.birthdate
        ? format(new Date(String(customer.birthdate)), 'MM/dd/yyyy')
        : '',
      socialSecurityNumber: customer.socialSecurityNumber ?? '',
      phoneNumber: customer.user?.phoneNumber
        ? parsePhoneNumberWithError(customer.user.phoneNumber).format(
            'NATIONAL'
          )
        : '',
      residentialStatus:
        housingStatusOptions(t).find(
          (o) => o.value === currentAddress?.housingStatus
        )?.label ?? '',
      addressDuration: `${currentAddress?.durationYears ?? 0} Yrs. ${currentAddress?.durationMonths ?? 0} Mon.`,
      mortgageOrRentMonthlyPay: `${currentAddress?.monthlyPayment?.toLocaleString()} USD`,
      previousAddressLength: `${previousAddress?.durationYears ?? 0} Yrs. ${previousAddress?.durationMonths ?? 0} Mon.`,
      previousAddress: previousAddress?.fullAddress ?? '',
      employerName: currentEmployment?.employerName ?? '',
      employerPhoneNumber: currentEmployment?.employerPhoneNumber
        ? parsePhoneNumberWithError(
            currentEmployment.employerPhoneNumber
          ).format('NATIONAL')
        : '',
      jobTitle: currentEmployment?.occupation ?? '',
      employmentStatus:
        employmentStatusOptions(t).find(
          (o) => o.value === currentEmployment?.employmentStatus
        )?.label ?? '',
      timeOnJob: `${currentEmployment?.durationYears ?? 0} Yrs. ${currentEmployment?.durationMonths ?? 0} Mon.`,
      salary: currentEmployment?.incomeAmount
        ? `${currentEmployment.incomeAmount.toLocaleString()} USD`
        : '',
      type:
        incomeIntervalOptions(t).find(
          (o) => o.value === currentEmployment?.incomeInterval
        )?.label ?? '',
      prevEmployerInfo: previousEmployment
        ? `${previousEmployment.employerName ?? ''}, ${
            previousEmployment.employerPhoneNumber
              ? 'tel: ' +
                parsePhoneNumberWithError(
                  previousEmployment.employerPhoneNumber
                ).format('NATIONAL')
              : ''
          }, length: ${previousEmployment.durationYears ?? 0} Yrs. ${previousEmployment.durationMonths ?? 0} Mon.`.trim()
        : '',
      otherIncmeSource: currentEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount: currentEmployment?.otherIncomeAmount
        ? `${currentEmployment.otherIncomeAmount.toLocaleString()} USD`
        : '',
      otherIncomeType:
        incomeIntervalOptions(t).find(
          (o) => o.value === currentEmployment?.otherIncomeInterval
        )?.label ?? '',
      refNameAddress: '', // We are not collecting this information anymore
      refPhoneNumber: '', // We are not collecting this information anymore
      refRelationship: '', // We are not collecting this information anymore
      dealershipName: dealership.name ?? 'no-dealership-name',
      signature: signature ?? '',
      customerName:
        `${customer.firstName} ${customer.middleName} ${customer.lastName}`.trim(),

      // Compliance fields
      dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
      name:
        signatureName ??
        `${customer.firstName ?? ''} ${customer.lastName ?? ''}`.trim(),
      ipAddress,
      deviceId,
      hasAcceptedAcknowledgements: hasAcceptedAcknowledgements
        ? true
        : undefined, // This is for modified credit applications that do not require the acknowledgements again
      hasAcceptedElectronicDisclosure: hasAcceptedElectronicDisclosure
        ? true
        : undefined, // This is for modified credit applications that do not require the acknowledgements again
      hasAcceptedModificationNotice: hasAcceptedModificationNotice
        ? true
        : undefined // This is for initial credit applications that do not require the modification notice acceptance
    }
  };
};

export default generateHardCreditApplicationFormSubmissionDataAction;
