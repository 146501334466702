// Generouted, changes to this file will be overridden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/credit-application/:dealershipSlug`
  | `/dashboard`
  | `/dashboard/:dealershipSlug/:transactionId`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType`
  | `/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType/capture`
  | `/dashboard/:dealershipSlug/:transactionId/coBuyer`
  | `/dashboard/:dealershipSlug/:transactionId/credit-application/acknowledgements`
  | `/dashboard/:dealershipSlug/:transactionId/credit-application/address`
  | `/dashboard/:dealershipSlug/:transactionId/credit-application/employment`
  | `/dashboard/:dealershipSlug/:transactionId/glove-compartment`
  | `/dashboard/:dealershipSlug/:transactionId/glove-compartment/:formSubmissionId`
  | `/dashboard/:dealershipSlug/:transactionId/glove-compartment/document/:documentId`
  | `/dashboard/:dealershipSlug/:transactionId/personalInformation`
  | `/dashboard/:dealershipSlug/:transactionId/prequal`
  | `/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId`
  | `/dashboard/:dealershipSlug/:transactionId/sign/next`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures`
  | `/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard`
  | `/onboarding`
  | `/onboarding/:dealershipSlug/:transactionId/step1`
  | `/onboarding/:dealershipSlug/:transactionId/step2`
  | `/prequal/:dealershipSlug`
  | `/scanner/:transactionId/:temporaryToken`
  | `/scanner/done`
  | `/signin`
  | `/signup`

export type Params = {
  '/credit-application/:dealershipSlug': { dealershipSlug: string }
  '/dashboard/:dealershipSlug/:transactionId': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType': { dealershipSlug: string; transactionId: string; documentType: string }
  '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType/capture': { dealershipSlug: string; transactionId: string; documentType: string }
  '/dashboard/:dealershipSlug/:transactionId/coBuyer': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/credit-application/acknowledgements': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/credit-application/address': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/credit-application/employment': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/glove-compartment': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/glove-compartment/:formSubmissionId': { dealershipSlug: string; transactionId: string; formSubmissionId: string }
  '/dashboard/:dealershipSlug/:transactionId/glove-compartment/document/:documentId': { dealershipSlug: string; transactionId: string; documentId: string }
  '/dashboard/:dealershipSlug/:transactionId/personalInformation': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/prequal': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/sign/next': { dealershipSlug: string; transactionId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard': { dealershipSlug: string; transactionId: string; vehicleId: string }
  '/onboarding/:dealershipSlug/:transactionId/step1': { dealershipSlug: string; transactionId: string }
  '/onboarding/:dealershipSlug/:transactionId/step2': { dealershipSlug: string; transactionId: string }
  '/prequal/:dealershipSlug': { dealershipSlug: string }
  '/scanner/:transactionId/:temporaryToken': { transactionId: string; temporaryToken: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
