import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  NumberInput,
  PhoneNumberInput,
  Select,
  TextInput
} from '@thedealersconcierge/components';
import { inputBackgroundTypes } from '@thedealersconcierge/components/src/input/inputConfig';
import {
  EmploymentStatus,
  IncomeInterval
} from '@thedealersconcierge/lib/codecs/tdc';
import { phoneNumber } from '@thedealersconcierge/lib/codecs/validation/phonenumber';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { literal, z } from 'zod';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import FormContainer from './FormContainer';

export type EmploymentFormValues = {
  employmentStatus: string;
  employerName: string;
  employerPhoneNumber: string;
  occupation: string;
  durationYears: string;
  durationMonths: string;
  incomeAmount: string;
  incomeInterval: string;
  otherIncomeSource: string;
  otherIncomeAmount: string;
  otherIncomeInterval: string;
};

export const ValidEmploymentSchema = (
  t: TFunction,
  type: 'CURRENT' | 'PREVIOUS'
) =>
  z.object({
    employmentStatus: EmploymentStatus,
    employerName: z.string(),
    employerPhoneNumber: phoneNumber().or(z.literal('')),
    occupation: z.string(),
    durationYears: z.string(),
    durationMonths: z.string(),
    incomeAmount: z
      .string()
      .min(type === 'CURRENT' ? 1 : 0, t('This field is required')),
    incomeInterval:
      type === 'CURRENT' ? IncomeInterval : IncomeInterval.or(z.literal('')),
    otherIncomeSource: z.string(),
    otherIncomeAmount: z.string(),
    otherIncomeInterval: IncomeInterval.or(z.literal(''))
  });

const EmploymentForm: FC<{
  form: ReactFormApi<EmploymentFormValues, ZodValidator>;
  isSubmitting: boolean;
  type: 'CURRENT' | 'PREVIOUS';
  className?: string;
  dataTestId?: string;
}> = ({ form, isSubmitting, type, className, dataTestId }) => {
  const { t } = useTranslation();

  return (
    <FormContainer
      title={
        type === 'CURRENT'
          ? t('Employment Information')
          : t('Previous Employment Information')
      }
      className={className}
      dataTestId={dataTestId}
    >
      <Fragment>
        <form.Field
          name="employmentStatus"
          validators={{
            onBlur: EmploymentStatus
          }}
        >
          {(field) => {
            return (
              <Select
                value={field.state.value}
                label={t('Employment Status')}
                placeholder={t('Employment Status')}
                required
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(EmploymentStatus.parse(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-6 desktop:col-span-8"
                options={employmentStatusOptions(t)}
                dataTestId={`${dataTestId}-employment-status`}
              />
            );
          }}
        </form.Field>

        <form.Subscribe selector={(form) => form.values.employmentStatus}>
          {(employmentStatus) => {
            return (
              <div
                className={classNames(
                  'col-span-4 tablet:col-span-6 desktop:col-span-8', // Parent grid layout
                  'grid grid-cols-subgrid gap-y-spacing-05 desktop:gap-y-spacing-06',
                  {
                    hidden: !['EMPLOYED', 'SELF_EMPLOYED'].includes(
                      employmentStatus
                    )
                  }
                )}
              >
                <form.Field
                  name="employerName"
                  validators={{
                    onBlur: z.string()
                  }}
                >
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label={t('Employer Name')}
                        placeholder={t('Employer Name')}
                        required
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors // "errors" might be undefined contrary to types
                          ?.at(0)
                          ?.toString()}
                        backgroundType={inputBackgroundTypes.LIGHT}
                        className="col-span-4 tablet:col-span-6 desktop:col-span-8"
                        dataTestId={`${dataTestId}-employer-name`}
                      />
                    );
                  }}
                </form.Field>

                <form.Field
                  name="employerPhoneNumber"
                  validators={{
                    onBlur: phoneNumber().or(z.literal(''))
                  }}
                >
                  {(field) => {
                    return (
                      <PhoneNumberInput
                        value={field.state.value}
                        label={t('Employer Phone Number')}
                        placeholder={t('Employer Phone Number')}
                        required
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors // "errors" might be undefined contrary to types
                          ?.at(0)
                          ?.toString()}
                        backgroundType={inputBackgroundTypes.LIGHT}
                        className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                        dataTestId={`${dataTestId}-employer-phone-number`}
                      />
                    );
                  }}
                </form.Field>

                <form.Field
                  name="occupation"
                  validators={{
                    onBlur: z.string()
                  }}
                >
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label={t('Role')}
                        placeholder={t('Role')}
                        required
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors // "errors" might be undefined contrary to types
                          ?.at(0)
                          ?.toString()}
                        backgroundType={inputBackgroundTypes.LIGHT}
                        className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                        dataTestId={`${dataTestId}-occupation`}
                      />
                    );
                  }}
                </form.Field>
              </div>
            );
          }}
        </form.Subscribe>

        <form.Field
          name="durationYears"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label={t('Duration (years)')}
                placeholder={t('Duration (years)')}
                required={true}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                decimalScale={0}
                dataTestId={`${dataTestId}-duration-years`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="durationMonths"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label={t('Duration (months)')}
                placeholder={t('Duration (months)')}
                required={true}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                decimalScale={0}
                dataTestId={`${dataTestId}-duration-months`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="incomeAmount"
          validators={{
            onBlur: z
              .string()
              .min(type === 'CURRENT' ? 1 : 0, t('This field is required'))
          }}
        >
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label={t('Gross Income')}
                placeholder={t('Gross Income')}
                required
                prefix="$"
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className={classNames(
                  'col-span-4 tablet:col-span-3 desktop:col-span-4',
                  { hidden: type === 'PREVIOUS' }
                )}
                decimalScale={2}
                dataTestId={`${dataTestId}-income-amount`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="incomeInterval"
          validators={{
            onBlur:
              type === 'CURRENT'
                ? IncomeInterval
                : IncomeInterval.or(literal(''))
          }}
        >
          {(field) => {
            return (
              <Select
                value={field.state.value}
                label={t('Per Time Period')}
                placeholder={t('Per Time Period')}
                required
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(IncomeInterval.parse(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className={classNames(
                  'col-span-4 tablet:col-span-3 desktop:col-span-4',
                  { hidden: type === 'PREVIOUS' }
                )}
                options={incomeIntervalOptions(t)}
                dataTestId={`${dataTestId}-income-interval`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="otherIncomeSource"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t('Other Income Source')}
                placeholder={t('Other Income Source')}
                required={false}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className={classNames(
                  'col-span-4 tablet:col-span-6 desktop:col-span-8',
                  { hidden: type === 'PREVIOUS' }
                )}
                dataTestId={`${dataTestId}-other-income-source`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="otherIncomeAmount"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label={t('Other Gross Income')}
                placeholder={t('Other Gross Income')}
                required={false}
                prefix="$"
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className={classNames(
                  'col-span-4 tablet:col-span-3 desktop:col-span-4',
                  { hidden: type === 'PREVIOUS' }
                )}
                decimalScale={2}
                dataTestId={`${dataTestId}-other-income-amount`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="otherIncomeInterval"
          validators={{
            onBlur: IncomeInterval.or(literal(''))
          }}
        >
          {(field) => {
            return (
              <Select
                value={field.state.value}
                label={t('Per Time Period (Other Income)')}
                placeholder={t('Per Time Period (Other Income)')}
                required={false}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(IncomeInterval.parse(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                backgroundType={inputBackgroundTypes.LIGHT}
                className={classNames(
                  'col-span-4 tablet:col-span-3 desktop:col-span-4',
                  { hidden: type === 'PREVIOUS' }
                )}
                options={incomeIntervalOptions(t)}
                dataTestId={`${dataTestId}-other-income-interval`}
              />
            );
          }}
        </form.Field>
      </Fragment>
    </FormContainer>
  );
};

export default EmploymentForm;
