import OfflineBadge from "@thedealersconcierge/components/src/badge/offlineBadge";
import { useEffect } from "react";
import { toast } from "react-toastify";

/**
 * This Hooks is used to render an offline notification
 * This will listen to `online` or `offline` event and show a badge when the user is offline
 * The badge will automatically closed when user went online
 *
 * IMPORTANT: backendUrl supplied must be from our own internal backend API, in this case it is our own root
 */
export default function useOfflineNotification(backendUrl: string) {
  const toastId = "offline_badge";

  const handleRetry = async (): Promise<void> => {
    try {
      // To check for connection we check to our own backend root
      await fetch(backendUrl, {
        mode: "no-cors",
      });

      // We use toast.update so we don't need to close and pop a new notification
      // This way notification can react more dynamically to the network state
      toast.update(toastId, {
        render: OfflineBadge,
        data: {
          isOnline: true,
        },
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Failed to retry for network");
    }
  };

  const offlineHandler = () => {
    if (!navigator.onLine) {
      toast(OfflineBadge, {
        data: {
          isOnline: false,
          onRetry: handleRetry,
        },
        autoClose: false,
        closeButton: true,
        toastId: toastId,
        closeOnClick: false,
        style: { maxWidth: "380px" },
      });
    }
  };

  useEffect(() => {
    // Initial check
    if (!navigator.onLine) {
      toast(OfflineBadge, {
        data: {
          isOnline: false,
          onRetry: handleRetry,
        },
        autoClose: false,
        closeButton: true,
        toastId: toastId,
        closeOnClick: false,
      });
    }

    // Event listeners for online/offline changes
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);
}
