import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { Button, NumberInput, Select } from '@thedealersconcierge/components';
import TextInput from '@thedealersconcierge/components/src/input/textInput';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleType } from '~/__generated__/backend/zeus';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import Spinner from '~/components/Spinner';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import vehicleConditionOptions from '~/config/formSelectionOptions/vehicleConditionOptions';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import {
  stringToVehicleBodyType,
  stringToVehicleCondition
} from '~/lib/enumMap';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import {
  VehicleType as Vehicle,
  vehicleSelector
} from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';

const TradeVehicleInformationForm: FC<{
  tradeVehicle?: Vehicle;
  isSubmitting: boolean;
  setIsSubmitting: (v: boolean) => void;
}> = ({ tradeVehicle, isSubmitting, setIsSubmitting }) => {
  const { t } = useTranslation();
  const [isPrefilling, setIsPrefilling] = useState(false);
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information'
  );
  const handleGoBack = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard',
      { params: { transactionId, vehicleId, dealershipSlug } }
    );
  };
  const form = useForm({
    defaultValues: {
      licensePlateNr: '',
      vin: tradeVehicle?.vin ?? '',
      model: tradeVehicle?.model ?? '',
      make: tradeVehicle?.make ?? '',
      year: tradeVehicle?.year ?? '',
      registrationName: tradeVehicle?.registrationName ?? '',
      registrationState: tradeVehicle?.registrationState ?? '',
      color: tradeVehicle?.color ?? '',
      bodyType: tradeVehicle?.bodyType,
      condition: tradeVehicle?.condition,
      mileage: tradeVehicle?.mileage?.toString() ?? ''
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        const args = {
          ...values.value,
          mileage: parseInt(values.value.mileage),
          licensePlateNr: undefined
        };
        // This is a major hack, please never use this
        // This code it a rework of some legacy code, and I did not have time to properly cleanup this
        delete args.licensePlateNr;

        await updateVehicleAction(
          transactionId,
          VehicleType.TRADE,
          vehicleId,
          args
        );

        await Promise.all([
          resetMeQuery(),
          queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        navigate(
          '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff',
          {
            params: { transactionId, vehicleId, dealershipSlug }
          }
        );
      } catch (error) {
        captureException(error);
        console.error('Error', error);

        if (error instanceof Error && error.message) {
          toast.error(error.message);
        } else {
          toast.error('Failed to save vehicle data');
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const vehicleBodyTypeOptions = [
    { value: 'SEDAN', label: t('Sedan') },
    { value: 'SUV', label: t('SUV') },
    { value: 'COUPE', label: t('Coupe') },
    { value: 'MINIVAN', label: t('Minivan') },
    { value: 'OTHER', label: t('Other') }
  ];

  const handlePrefillFromPlate = async () => {
    const stateForPrefilling = form.getFieldValue('registrationState');
    const plateForPrefilling = form.getFieldValue('licensePlateNr');
    if (!stateForPrefilling || !plateForPrefilling) {
      return;
    }
    try {
      setIsPrefilling(true);
      const prefillValues = await gqlMutationClient()({
        prefillLicensePlate: [
          {
            state: stateForPrefilling,
            licensePlate: plateForPrefilling
          },
          {
            __typename: true,
            '...on MutationPrefillLicensePlateSuccess': {
              data: {
                make: true,
                model: true,
                year: true,
                trim: true,
                vin: true
              }
            },
            '...on GraphQLError': {
              message: true
            }
          }
        ]
      });

      if (prefillValues.prefillLicensePlate?.__typename === 'GraphQLError') {
        toast.error('Could not fetch prefill values');
        return;
      }

      const values = prefillValues.prefillLicensePlate?.data;

      form.setFieldValue('vin', values?.vin ?? '');
      form.setFieldValue('make', values?.make ?? '');
      form.setFieldValue('model', values?.model ?? '');
      form.setFieldValue('year', values?.year ?? '');
    } finally {
      setIsPrefilling(false);
    }
  };

  return (
    <Fragment>
      <form
        className="flex flex-col space-y-12 overflow-y-scroll max-w-full overflow-x-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        {/* This entire component should use a FormContainer and the mandated way to do layout
            Feel free to lift it when working on it.
        */}

        <div className="flex flex-col gap-4">
          {/* Show the fields require for prefilling first */}
          <div className="w-full grid grid-cols-7 gap-4">
            <div className="col-span-2 flex justify-center items-center">
              <form.Field name="registrationState">
                {(field) => {
                  return (
                    <Select
                      value={field.state.value}
                      label={t('Registration State')}
                      placeholder={t('Registration State')}
                      options={stateOptions}
                      disabled={isSubmitting}
                      required
                      errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                      onSelect={(e) => {
                        field.handleChange(e.value);
                      }}
                      backgroundType="LIGHT"
                    />
                  );
                }}
              </form.Field>
            </div>

            <div className="col-span-3 flex justify-center items-center">
              <form.Field name="licensePlateNr">
                {(field) => {
                  return (
                    <TextInput
                      name={field.name}
                      value={field.state.value}
                      label={t('License Plate Nr.')}
                      placeholder={t('License Plate Nr.')}
                      disabled={isSubmitting}
                      required
                      errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                      onChange={(e) => {
                        field.handleChange(e);
                      }}
                      backgroundType="LIGHT"
                    />
                  );
                }}
              </form.Field>
            </div>

            <div className="col-span-2 flex justify-center items-center">
              <Button
                variant="PRIMARY"
                label="Prefill"
                onClick={() => void handlePrefillFromPlate()}
                isLoading={isPrefilling}
              />
            </div>
          </div>

          <div>
            <form.Field name="registrationName">
              {(field) => {
                return (
                  <TextInput
                    name={field.name}
                    value={field.state.value}
                    label={t('Registration Name')}
                    placeholder={t('Registration Name')}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onChange={(e) => {
                      field.handleChange(e);
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>
          </div>
        </div>

        <div className="space-y-6">
          <form.Field name="vin">
            {(field) => {
              return (
                <TextInput
                  name={field.name}
                  value={field.state.value}
                  label={t('VIN#')}
                  placeholder={t('VIN#')}
                  disabled={isSubmitting}
                  required
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e);
                  }}
                  maxLength={17}
                  backgroundType="LIGHT"
                />
              );
            }}
          </form.Field>

          <form.Field name="make">
            {(field) => {
              return (
                <TextInput
                  name={field.name}
                  value={field.state.value}
                  label={t('Make')}
                  placeholder={t('Make')}
                  disabled={isSubmitting}
                  required
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e);
                  }}
                  backgroundType="LIGHT"
                />
              );
            }}
          </form.Field>

          <div className="grid grid-cols-2 gap-6 items-end">
            <form.Field name="model">
              {(field) => {
                return (
                  <TextInput
                    name={field.name}
                    value={field.state.value}
                    label={t('Model')}
                    placeholder={t('Model')}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onBlur={field.handleBlur}
                    onChange={(e) => {
                      field.handleChange(e);
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>

            <form.Field name="year">
              {(field) => {
                return (
                  <TextInput
                    name={field.name}
                    value={field.state.value}
                    label={t('Year')}
                    placeholder={t('Year')}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onBlur={field.handleBlur}
                    onChange={(e) => {
                      field.handleChange(e);
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>

            <form.Field name="bodyType">
              {(field) => {
                return (
                  <Select
                    value={field.state.value ?? ''}
                    label={t('Body Type')}
                    placeholder={t('Body Type')}
                    options={vehicleBodyTypeOptions}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onBlur={field.handleBlur}
                    onSelect={(e) => {
                      field.handleChange(stringToVehicleBodyType(e.value));
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>

            <form.Field name="condition">
              {(field) => {
                return (
                  <Select
                    value={field.state.value ?? ''}
                    label={t('Condition')}
                    placeholder={t('Condition')}
                    options={vehicleConditionOptions}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onSelect={(e) => {
                      field.handleChange(stringToVehicleCondition(e.value));
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>

            <form.Field name="color">
              {(field) => {
                return (
                  <TextInput
                    name={field.name}
                    value={field.state.value}
                    label={t('Color')}
                    placeholder={t('Color')}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onChange={(e) => {
                      field.handleChange(e);
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>

            <form.Field name="mileage">
              {(field) => {
                return (
                  <NumberInput
                    name={field.name}
                    value={field.state.value}
                    label={t('Mileage')}
                    placeholder={t('Mileage')}
                    disabled={isSubmitting}
                    required
                    errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                    onChange={(e) => {
                      field.handleChange(e);
                    }}
                    backgroundType="LIGHT"
                  />
                );
              }}
            </form.Field>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <Button
            variant="SECONDARY"
            disabled={isSubmitting}
            onClick={handleGoBack}
            label={t('Back')}
          />

          <form.Subscribe
            selector={(state) => [state.values]}
            children={([values]) => {
              const canContinue =
                !!values.vin &&
                !!values.make &&
                !!values.model &&
                !!values.year &&
                !!values.bodyType &&
                !!values.condition &&
                !!values.color &&
                !!values.mileage &&
                !!values.registrationName &&
                !!values.registrationState;

              return (
                <Button
                  isLoading={isSubmitting}
                  disabled={!canContinue}
                  label={t('Next')}
                />
              );
            }}
          />
        </div>
      </form>
    </Fragment>
  );
};

const TradeVehicleInformationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information'
  );
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'tradeVehicle', vehicleId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId
          },
          {
            tradeVehicle: vehicleSelector
          }
        ]
      })
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Trade Registration')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={4}
        currentStep={2}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard"
            params={{ transactionId, vehicleId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center overflow-y-scroll px-6">
        <div className="flex flex-col max-w-screen-md py-10 space-y-12 justify-between w-full">
          <div className="space-y-8 md:space-y-16">
            <div className="space-y-4">
              <h2>{t('Step 2: Vehicle Information')}</h2>

              <p>
                {t(
                  'Please fill in or verify the information in the fields below.'
                )}
              </p>
            </div>

            {isLoading && (
              <div className="flex flex-1 items-center justify-center">
                <Spinner />
              </div>
            )}

            {!isLoading && (
              <TradeVehicleInformationForm
                tradeVehicle={data?.transaction?.tradeVehicle}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeVehicleInformationPage;
