import { TFunction } from 'i18next';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { customerSelector } from '~/querySelectors/customer';
import { DealershipType } from '~/querySelectors/dealership';
import { uploadCreditApplicationWorkflow } from '../uploadCreditApplicationWorkflow';
import generateAndUploadCreditApplicationModificationNoticeWorkflow from './generateAndUploadCreditApplicationModificationNoticeWorkflow';

const modifyCreditApplicationWorkflow = async ({
  t,
  transactionId,
  userId,
  dealership,
  ipAddress,
  deviceId,
  hasAcceptedModificationNotice
}: {
  t: TFunction;
  transactionId: string;
  userId: string;
  dealership: DealershipType;
  ipAddress: string;
  deviceId: string;
  hasAcceptedModificationNotice: boolean;
}) => {
  const customerResp = await gqlQueryClient()({
    customer: [
      {
        transactionId,
        userId
      },
      customerSelector
    ]
  });
  const customer = customerResp.customer;

  if (!customer) {
    throw new Error('Could not fetch customer data');
  }

  /**
   * Generate and upload credit application modification notice
   */
  const modificationNoticeFile =
    await generateAndUploadCreditApplicationModificationNoticeWorkflow({
      t,
      name: `${customer.firstName ?? ''} ${customer.lastName ?? ''}`.trim(),
      ipAddress
    });

  if (!modificationNoticeFile.fileId) {
    throw new Error('Failed to generated modification notice file');
  }

  /**
   * Generate and upload credit application PDF
   */
  const [creditApplicationDocument, formSubmissionData] =
    await uploadCreditApplicationWorkflow({
      customer,
      dealership,
      ipAddress,
      hasAcceptedModificationNotice,
      deviceId,
      t
    });

  if (!creditApplicationDocument.fileId) {
    throw new Error('Could not upload credit application');
  }

  const resp = await gqlMutationClient()({
    modifyHardCreditApplication: [
      {
        transactionId,
        formSubmissionData,
        signedUploadedModificationDocumentId: modificationNoticeFile.fileId,
        signedUploadedCreditApplicationDocumentId:
          creditApplicationDocument.fileId
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationModifyHardCreditApplicationSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !resp.modifyHardCreditApplication ||
    resp.modifyHardCreditApplication.__typename === 'GraphQLError'
  ) {
    throw new Error(
      resp.modifyHardCreditApplication?.message || 'Unexpected Error'
    );
  }
};

export default modifyCreditApplicationWorkflow;
