import { Add } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Selector, VehicleType } from '~/__generated__/backend/zeus';
import DocumentIcon from '~/components/icons/DocumentIcon';
import AdditionalDocumentsIllustration from '~/components/illustrations/AdditionalDocumentsIllustration';
import CreditApplicationIllustration from '~/components/illustrations/CreditApplicationIllustration';
import PreQualIllustration from '~/components/illustrations/PreQualIllustration';
import DropDown from '~/components/inputs/DropDown';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { getReadablePrincipalPriorUse } from '~/lib/enumReadable';
import customerQuery from '~/queries/customerQuery';
import { resetMeQuery } from '~/queries/meQuery';
import {
  TransactionQueryType,
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { VehicleType as Vehicle } from '~/querySelectors/vehicle';
import { useNavigate, useParams } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';
import { navigateToTransaction } from '../../../_utils';
import ParaPartDataSection from './ParaPartDataSection';

const transactionSelector = Selector('Transaction')({
  id: true,
  createdAt: true,
  buyerId: true,
  hardCreditApplDmsSubmittedAt: true,
  customerSharedData: {
    buyerOnboardingStep: true,
    coBuyerOnboardingStep: true
  },
  dealership: {
    name: true,
    slug: true
  },
  vehicle: {
    make: true,
    model: true
  }
});

/**
 * TransactionDataSection
 *
 * This component is a section component and not an abstraction. This means
 * that it is not build for being extensible and abstract. Therefore is makes
 * sense to inline a lot code in it rather than passing it as props
 *
 * @param param0
 * @returns
 */
const TransactionDataSection: FC<{
  transaction: TransactionQueryType['transaction'];
  transactionRole: 'BUYER' | 'CO_BUYER';
  selectedTransactionId: string;
  onEditPurchaseVehicle: () => void;
  onAddPurchaseVehicle: () => void;
  tradeVehicle?: Vehicle;
  purchaseVehicle?: Vehicle;
  creatingPurchaseVehicle: boolean;
}> = ({
  transaction,
  transactionRole,
  selectedTransactionId,
  onEditPurchaseVehicle,
  onAddPurchaseVehicle,
  tradeVehicle,
  purchaseVehicle,
  creatingPurchaseVehicle
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [creatingTradeVehicle, setCreatingTradeVehicle] = useState(false);
  const dealerKiosk = useAtomValue(kioskDealershipAtom);
  // This is a section type component, hence we know it is only used once
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );

  const { data: meData } = useQuery({
    queryKey: ['transactionOverview'],
    queryFn: async () =>
      gqlQueryClient()({
        me: {
          user: {
            id: true,
            buyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ],
            coBuyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ]
          }
        }
      })
  });

  const buyerFor = meData?.me?.user?.buyerFor?.edges;
  const coBuyerFor = meData?.me?.user?.coBuyerFor?.edges;

  const allTransactions = (
    buyerFor?.map((t) => ({
      role: 'BUYER',
      ...t.node
    })) ?? []
  ).concat(
    coBuyerFor?.map((t) => ({
      role: 'CO_BUYER',
      ...t.node
    })) ?? []
  );

  const { data: customerData } = useQuery(
    customerQuery(
      transactionId,
      transactionRole === 'CO_BUYER'
        ? transaction?.coBuyerId
        : transaction?.buyerId,
      dealershipSlug
    )
  );
  const customer = customerData?.customer;

  const prequalApplication = customer?.hasPrequalApplication;

  const latestHardCreditApplication =
    customer?.hardCreditApplications?.edges?.at(0)?.node;

  const onEditHardCreditApplication = () => {
    navigate(
      {
        pathname:
          '/dashboard/:dealershipSlug/:transactionId/credit-application/address',
        search: 'modification=true'
      },
      {
        params: {
          dealershipSlug,
          transactionId
        }
      }
    );
  };

  const handleGoToTradeVehicle = async () => {
    let vehicleId = tradeVehicle?.id;

    if (!tradeVehicle) {
      setCreatingTradeVehicle(true);

      const vehicle = await gqlMutationClient()({
        createVehicle: [
          {
            transactionId: transaction?.id ?? 'should-never-happen',
            vehicleType: VehicleType.TRADE
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationCreateVehicleSuccess': {
              data: {
                status: true,
                id: true
              }
            }
          }
        ]
      });

      if (
        vehicle.createVehicle?.__typename === 'MutationCreateVehicleSuccess'
      ) {
        await Promise.all([
          resetMeQuery(),
          resetTransactionQuery(
            transaction?.id ?? 'should-never-happen',
            dealershipSlug
          )
        ]);

        vehicleId = vehicle.createVehicle.data.id;

        setCreatingTradeVehicle(false);
      } else {
        console.error(vehicle.createVehicle?.message);
        window.alert(
          'Could not add trade vehicle. Please try again or contact support.'
        );
      }
    }

    navigate(
      '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard',
      {
        params: {
          transactionId,
          dealershipSlug,
          vehicleId: vehicleId ?? 'no-vehicle-id'
        }
      }
    );
  };

  const onStartHardCreditApplication = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/credit-application/address',
      {
        params: {
          transactionId,
          dealershipSlug
        }
      }
    );
  };

  const { data: pendingDocumentsForSigning } = useQuery({
    enabled: Boolean(transaction && customer),
    queryKey: ['prePurchaseCount', dealershipSlug, transaction?.id],
    queryFn: () =>
      gqlQueryClient({ dealershipSlug })({
        customer: [
          {
            transactionId: transaction?.id ?? 'should-never-happen',
            userId: customer?.userId ?? 'no enabled'
          },
          {
            nextDocumentsToSign: [
              // We don't want to fetch the actual documents, just the count
              { first: 0 },
              {
                totalCount: true
              }
            ]
          }
        ]
      })
  });

  const missingPrePurchaseDocs =
    pendingDocumentsForSigning?.customer?.nextDocumentsToSign?.totalCount ?? 0;

  return (
    <div
      className="flex justify-center bg-primary border-t border-inactive px-6 py-4"
      data-test-id={'consumer-transaction-data-section'}
    >
      <div className="w-full max-w-screen-lg flex-col space-y-4">
        <div className="grid grid-cols-2 gap-2 md:flex md:flex-row">
          {/**
           * Select the transaction which the user wants to look at
           */}
          <div className="space-y-1">
            <p className="text-body-3 text-tertiary">{t('Transaction')}</p>

            <div className="w-full md:w-80">
              <DropDown
                fieldName="transaction"
                value={selectedTransactionId}
                labelText={t('Transaction')}
                inputClassName="truncate max-w-72"
                options={allTransactions.map((t) => {
                  const date = format(t.createdAt ?? new Date(), 'MM/dd/yyyy');
                  const vehicleName = t.vehicle
                    ? ` | ${t.vehicle.make} ${t.vehicle.model}`
                    : '';

                  return {
                    label: `${date}${vehicleName} - ${t.dealership?.name} `,
                    value: t.id ?? 'should-never-happen'
                  };
                })}
                variant="STAND_ALONE"
                onChange={(v) => {
                  const t = allTransactions.find(
                    (ts) => ts.id === v.target.value
                  );

                  if (!t) {
                    return;
                  }

                  navigateToTransaction(
                    t.id ?? 'should-never-happen',
                    dealershipSlug,
                    navigate,
                    meData?.me?.user?.id === t.buyerId
                      ? t.customerSharedData?.buyerOnboardingStep
                      : t.customerSharedData?.coBuyerOnboardingStep
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4 py-4 bg-secondary p-6 rounded-lg shadow-border">
          <div className="flex flex-col md:flex-row w-full justify-between space-y-2 items-center">
            <div className="flex flex-row space-x-2 items-center">
              <DocumentIcon className="size-4 icon-secondary" />

              <h2>{t('Documents & Forms')}</h2>
            </div>

            <Button
              label={t('View Documents')}
              variant="SECONDARY"
              size="SMALL"
              onClick={() => {
                navigate(
                  '/dashboard/:dealershipSlug/:transactionId/glove-compartment',
                  {
                    params: { transactionId, dealershipSlug }
                  }
                );
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {/**
             * The user hasn't pre-qualified yet, so they have the option to start the flow
             */}
            {!prequalApplication && (
              <div className="flex flex-col space-y-4 p-6 bg-primary shadow-border rounded-lg items-center">
                <h3>{t('Pre-qualify')}</h3>

                <PreQualIllustration className="h-24" />

                <Button
                  label={t("Let's Go")}
                  size="MEDIUM"
                  onClick={() => {
                    navigate(
                      '/dashboard/:dealershipSlug/:transactionId/prequal',
                      {
                        params: {
                          transactionId,
                          dealershipSlug
                        }
                      }
                    );
                  }}
                  dataTestId={'consumer-dashboard-start-prequal-button'}
                />
              </div>
            )}

            <div className="flex flex-col space-y-4 p-6 bg-primary shadow-border rounded-lg items-center">
              <h3>{t('Credit Application')}</h3>

              <CreditApplicationIllustration className="h-24" />

              {/**
               * The user hasn't created a hard credit application yet, so they can create a new one
               */}
              {!latestHardCreditApplication && (
                <Button
                  label={t("Let's Go")}
                  size="MEDIUM"
                  onClick={onStartHardCreditApplication}
                  dataTestId={'consumer-dashboard-start-hca-button'}
                />
              )}

              {/**
               * The user already has submitted a hard credit application.
               * Then the user can edit it.
               */}
              {latestHardCreditApplication?.formSubmission?.id && ( // The user has submitted a hard credit application already
                <Button
                  label={t('Edit')}
                  size="MEDIUM"
                  dataTestId="consumer-dashboard-edit-hca-button"
                  onClick={onEditHardCreditApplication}
                />
              )}
            </div>

            {missingPrePurchaseDocs > 0 && (
              <div className="flex flex-col space-y-4 p-6 bg-primary shadow-border rounded-lg items-center">
                <h3>
                  {t('{{numDocs}} Documents for Signing', {
                    numDocs: missingPrePurchaseDocs
                  })}
                </h3>

                <AdditionalDocumentsIllustration className="h-24" />

                <Button
                  label={t('E-Sign')}
                  onClick={() => {
                    navigate(
                      '/dashboard/:dealershipSlug/:transactionId/sign/next',
                      {
                        params: {
                          transactionId,
                          dealershipSlug
                        }
                      }
                    );
                  }}
                  dataTestId="consumer-start-signing-button"
                />
              </div>
            )}

            <div className="flex flex-col space-y-4 p-6 bg-primary shadow-border rounded-lg items-center">
              <h3>{t('Additional Documents')}</h3>

              <AdditionalDocumentsIllustration className="h-24" />

              <Button
                label={t('Add')}
                size="MEDIUM"
                iconLeft={<Add />}
                onClick={() => {
                  navigate(
                    '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments',
                    {
                      params: { transactionId, dealershipSlug }
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames('grid gap-2 grid-cols-1', {
            'md:grid-cols-3': !tradeVehicle && !purchaseVehicle,
            'md:grid-cols-2': tradeVehicle || purchaseVehicle
          })}
        >
          {/**
           * The transaction doesn't have a trade vehicle yet
           */}
          {!tradeVehicle && (
            <div
              className={classNames(
                'bg-primary rounded-lg shadow-border p-6 flex-col space-y-4',
                { 'md:col-span-2': purchaseVehicle }
              )}
            >
              <h2>{t('Vehicle for Trade')}</h2>

              {transactionRole === 'BUYER' && (
                <Button
                  label={t('Add')}
                  iconLeft={<Add />}
                  onClick={() => {
                    void handleGoToTradeVehicle();
                  }}
                  isLoading={creatingTradeVehicle}
                  dataTestId="vehicle-for-trade-add-button"
                />
              )}
            </div>
          )}

          {/**
           * The transaction has a trade vehicle
           */}
          {tradeVehicle && (
            <div className="grid grid-cols-2 md:col-span-2 bg-primary rounded-lg shadow-border p-6">
              <div className="flex-col space-y-4 col-span-2">
                <div className="flex flex-row space-x-4 items-center justify-between">
                  <h2>{t('Vehicle for Trade')}</h2>

                  {transactionRole === 'BUYER' && (
                    <Button
                      label={t('Edit')}
                      variant="GHOST"
                      size="SMALL"
                      onClick={() => {
                        void handleGoToTradeVehicle();
                      }}
                      dataTestId="vehicle-for-trade-edit-button"
                    />
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-fit">
                  <div className="flex flex-row space-x-4">
                    <p>{t('Make')}</p>

                    <p className="text-tertiary">{tradeVehicle.make ?? '-'}</p>
                  </div>

                  <div className="flex flex-row space-x-4">
                    <p>{t('Model')}</p>

                    <p className="text-tertiary">{tradeVehicle.model ?? '-'}</p>
                  </div>

                  <div className="flex flex-row space-x-4">
                    <p>{t('Year')}</p>

                    <p className="text-tertiary">{tradeVehicle.year ?? '-'}</p>
                  </div>
                </div>
              </div>

              {/**
               * TODO: Re-enable when ready for mobile devices
               */}
              {/* <div className="flex flex-col justify-between pl-6">
                <h2>Vehicle Photos</h2>

                <button
                  className="flex justify-center items-center icon-inverse size-6 rounded-full bg-interactive-primary"
                  onClick={onAddTradeVehiclePhotos}
                >
                  <CrossIcon className="size-4 rotate-45" />
                </button>
              </div> */}
            </div>
          )}

          {/**
           * The transaction doesn't have a purchase vehicle yet
           */}
          {!purchaseVehicle && (
            <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4">
              <h2>{t('Vehicle for Purchase')}</h2>

              {transactionRole === 'BUYER' && (
                <Button
                  label={t('Add')}
                  iconLeft={<Add />}
                  onClick={onAddPurchaseVehicle}
                  isLoading={creatingPurchaseVehicle}
                  dataTestId="vehicle-for-purchase-add-button"
                />
              )}
            </div>
          )}

          {/**
           * The transaction has a purchase vehicle
           */}
          {purchaseVehicle && (
            <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4">
              <div className="flex flex-row space-x-4 items-center justify-between">
                <h2>{t('Vehicle for Purchase')}</h2>

                {transactionRole === 'BUYER' && (
                  <Button
                    label={t('Edit')}
                    variant="GHOST"
                    size="SMALL"
                    onClick={onEditPurchaseVehicle}
                    dataTestId="vehicle-for-purchase-edit-button"
                  />
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="flex flex-row space-x-4 md:col-span-2">
                  <p className="w-20 md:w-16">{t('Type')}</p>

                  <p className="text-tertiary">
                    {purchaseVehicle.isUsed ? t('Used') : t('New')}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:w-16">{t('Principal Prior Use')}</p>

                  <p className="text-tertiary">
                    {purchaseVehicle.principalPriorUse
                      ? getReadablePrincipalPriorUse(
                          purchaseVehicle.principalPriorUse
                        )
                      : '-'}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:w-16">{t('Stock No')}.</p>

                  <p className="text-tertiary truncate">
                    {purchaseVehicle.stockNumber ?? '-'}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:w-16">{t('Model')}</p>

                  <p className="text-tertiary truncate">
                    {purchaseVehicle.model ?? '-'}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:min-w-16">{t('VIN#')}</p>

                  <p className="text-tertiary truncate">
                    {purchaseVehicle.vin ?? '-'}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:w-16">{t('Year')}</p>

                  <p className="text-tertiary truncate">
                    {purchaseVehicle.year ?? '-'}
                  </p>
                </div>

                <div className="flex flex-row space-x-4">
                  <p className="w-20 md:w-16">{t('Make')}</p>

                  <p className="text-tertiary truncate">
                    {purchaseVehicle.make ?? '-'}
                  </p>
                </div>
              </div>
            </div>
          )}

          <ParaPartDataSection
            seeForRole={transactionRole === 'BUYER' ? 'CO_BUYER' : 'BUYER'}
            transaction={transaction}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionDataSection;
