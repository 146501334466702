import { PDFDocument } from "pdf-lib";

/**
 * Validates if a file is a valid, by loading it using pdf-lib
 * @param file - File
 * @returns A Promise<boolean> whether the file is a valid PDF
 *
 * This needs to be tested extensively since this process is detrimental and could be a blocking error if not handled properly
 * Test cases see: pdfValidator.test.ts
 */
export const validatePdf = async (
  file: File | Blob
): Promise<"INVALID" | "VALID" | "ENCRYPTED"> => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer, {
      ignoreEncryption: true,
    });

    // ignoreEncryption: true is used so PDFDocument does not throw an error and loads it with a flag
    // This could be removed, but keeping it this way feels better and more verbose
    // https://github.com/Hopding/pdf-lib#encryption-handling
    if (pdfDoc.isEncrypted) {
      return "ENCRYPTED";
    }

    return "VALID";
  } catch (error) {
    return "INVALID";
  }
};
