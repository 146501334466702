import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';
import { TFunction } from 'i18next';

type Fields = 'name' | 'dateTime' | 'deviceId' | 'ipAddress';

export const creditApplicationModificationNotice: (
  t: TFunction
) => DocumentTemplate<Fields> = (t: TFunction) => ({
  fileUrl: t('credit-application-modification-notice', {
    ns: 'files',
    defaultValue: '/docs/credit-application-modification-notice.pdf'
  }),
  fields: legalStamps('form-credit-application-modification-notice', [0])
});
