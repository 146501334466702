import { ChevronLeft } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, ProgressBar } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { hasExistingUserAction } from '~/actions/auth/hasExistingUser';
import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import EmploymentForm, {
  EmploymentFormValues,
  ValidEmploymentSchema
} from '~/components/forms/EmploymentForm';
import config from '~/config';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import {
  stringToEmploymentStatus,
  stringToHousingStatus,
  stringToIncomeInterval
} from '~/lib/enumMap';
import dealershipQuery from '~/queries/dealershipQuery';
import { useParams } from '~/router';
import BasicInformationForm, {
  CreditApplicationBasicInformationFormValues,
  ValidCreditApplicationBasicInformationSchema
} from '../../../components/forms/CreditApplicationBasicInformationForm';

import AddressForm, {
  AddressFormValues,
  ValidAddressSchema
} from '~/components/forms/AddressForm';

import { useStore } from '@tanstack/react-store';
import CreditApplicationConsentForm, {
  CreditApplicationConsentFormValues,
  ValidCreditApplicationConsentSchema
} from '~/components/forms/CreditApplicationConsentForm';
import ConfirmationModal from './_components/ConfirmationModal';
import LoginModal from './_components/LoginModal';

const CreditApplicationPage = () => {
  const { t } = useTranslation();
  const { dealershipSlug } = useParams('/credit-application/:dealershipSlug');
  const { data: dealership } = useQuery(
    dealershipQuery({ slug: dealershipSlug })
  );
  const { data: publicData } = useQuery({
    queryKey: ['public'],
    queryFn: () => {
      return gqlQueryClient()({
        public: {
          ipAddress: true
        }
      });
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const basicInformationForm = useForm<
    CreditApplicationBasicInformationFormValues,
    ZodValidator
  >({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      socialSecurityNumber: '',
      phoneNumber: '',
      email: ''
    },
    validators: {
      onSubmit: ValidCreditApplicationBasicInformationSchema(t)
    },
    validatorAdapter: zodValidator()
  });
  const currentAddressForm = useForm<AddressFormValues, ZodValidator>({
    defaultValues: {
      vehicleRegistrationAddressAnswer: '',
      street: '',
      apartmentDetails: '',
      city: '',
      state: '',
      zipCode: '',
      housingStatus: '',
      monthlyPayment: '',
      durationYears: '',
      durationMonths: ''
    },
    validators: {
      onSubmit: ValidAddressSchema(t, 'CURRENT', 'WEB')
    },
    validatorAdapter: zodValidator()
  });
  const previousAddressForm = useForm<AddressFormValues, ZodValidator>({
    defaultValues: {
      vehicleRegistrationAddressAnswer: '',
      street: '',
      apartmentDetails: '',
      city: '',
      state: '',
      zipCode: '',
      housingStatus: '',
      monthlyPayment: '',
      durationYears: '',
      durationMonths: ''
    },
    validators: {
      onSubmit: ValidAddressSchema(t, 'PREVIOUS', 'WEB')
    },
    validatorAdapter: zodValidator()
  });
  const currentEmploymentForm = useForm<EmploymentFormValues, ZodValidator>({
    defaultValues: {
      employmentStatus: '',
      employerName: '',
      employerPhoneNumber: '',
      occupation: '',
      durationYears: '',
      durationMonths: '',
      incomeAmount: '',
      incomeInterval: '',
      otherIncomeSource: '',
      otherIncomeAmount: '',
      otherIncomeInterval: ''
    },
    validators: {
      onSubmit: ValidEmploymentSchema(t, 'CURRENT')
    },
    validatorAdapter: zodValidator()
  });
  const previousEmploymentForm = useForm<EmploymentFormValues, ZodValidator>({
    defaultValues: {
      employmentStatus: '',
      employerName: '',
      employerPhoneNumber: '',
      occupation: '',
      durationYears: '',
      durationMonths: '',
      incomeAmount: '',
      incomeInterval: '',
      otherIncomeSource: '',
      otherIncomeAmount: '',
      otherIncomeInterval: ''
    },
    validators: {
      onSubmit: ValidEmploymentSchema(t, 'PREVIOUS')
    },
    validatorAdapter: zodValidator()
  });
  const consentForm = useForm<CreditApplicationConsentFormValues, ZodValidator>(
    {
      defaultValues: {
        hasAcceptedAcknowledgements: false,
        hasAcceptedElectronicDisclosure: false,
        hasAcceptedContactConsent: false,
        fullName: '',
        signature: ''
      },
      validators: {
        onSubmit: ValidCreditApplicationConsentSchema(t, 'WEB')
      },
      validatorAdapter: zodValidator()
    }
  );
  const hasFilledOutCurrentAddress = useStore(
    currentAddressForm.store,
    ({ values, isValid }) => {
      return (
        isValid &&
        (Boolean(values.durationMonths) || Boolean(values.durationYears)) // Either duration in months or years has to be provided
      );
    }
  );
  const atLeastTwoYearsAtCurrentAddress = useStore(
    currentAddressForm.store,
    ({ values }) => {
      return (
        parseInt(values.durationYears ? values.durationYears : '0') * 12 +
          parseInt(values.durationMonths ? values.durationMonths : '0') >=
        24
      );
    }
  );
  const hasFilledOutCurrentEmployment = useStore(
    currentEmploymentForm.store,
    ({ values, isValid }) => {
      return (
        isValid &&
        (['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus)
          ? Boolean(values.employerName) &&
            Boolean(values.employerPhoneNumber) &&
            Boolean(values.occupation)
          : true) &&
        (Boolean(values.durationMonths) || Boolean(values.durationYears)) // Either duration in months or years has to be provided
      );
    }
  );
  const atLeastTwoYearsAtCurrentEmployment = useStore(
    currentEmploymentForm.store,
    ({ values }) => {
      return (
        parseInt(values.durationYears ? values.durationYears : '0') * 12 +
          parseInt(values.durationMonths ? values.durationMonths : '0') >=
        24
      );
    }
  );
  const handleGoBack = () => {
    setStep((prev) => {
      switch (prev) {
        case 2:
          return 1;
        case 3:
          return 2;
        default:
          return prev;
      }
    });
  };
  const handleGoToNext = () => {
    // Check that basic and address information are valid before proceeding
    if (step === 1) {
      const basicInformationValid =
        !basicInformationForm.validateSync('submit').hasErrored;
      const currentAddressValid =
        !currentAddressForm.validateSync('submit').hasErrored;
      const previousAddressValid =
        !previousAddressForm.validateSync('submit').hasErrored;

      if (!basicInformationValid || !currentAddressValid) {
        return;
      }

      if (!atLeastTwoYearsAtCurrentAddress && !previousAddressValid) {
        toast.error(t('At least two years of address history are required.'));
        return;
      }
      // Check that employment information is valid before proceeding
    } else if (step === 2) {
      const currentEmploymentValid =
        !currentEmploymentForm.validateSync('submit').hasErrored;
      const previousEmploymentValid =
        !previousEmploymentForm.validateSync('submit').hasErrored;

      if (!currentEmploymentValid) {
        return;
      }

      if (!atLeastTwoYearsAtCurrentEmployment && !previousEmploymentValid) {
        toast.error(
          t('At least two years of employment history are required.')
        );
        return;
      }

      // We have to check the conditionally required employer fields when the employment status is 'employed' or 'self_employed'
      if (
        ['EMPLOYED', 'SELF_EMPLOYED'].includes(
          currentEmploymentForm.state.values.employmentStatus
        )
      ) {
        if (currentEmploymentForm.state.values.employerName === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer name is required'));

          return;
        }

        if (currentEmploymentForm.state.values.employerPhoneNumber === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer phone number is required'));

          return;
        }

        if (currentEmploymentForm.state.values.occupation === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The occupation is required'));

          return;
        }
      }
    }

    setStep((prev) => {
      switch (prev) {
        case 1:
          return 2;
        case 2:
          return 3;
        default:
          return prev;
      }
    });
  };
  const submitCreditApplication = async () => {
    try {
      const basicInformation = basicInformationForm.state.values;
      const currentAddress = currentAddressForm.state.values;
      const previousAddress = previousAddressForm.state.values;
      const currentEmployment = currentEmploymentForm.state.values;
      const previousEmployment = previousEmploymentForm.state.values;
      const consentData = consentForm.state.values;
      const currentAddressHousingStatus = stringToHousingStatus(
        currentAddress.housingStatus
      );
      const currentEmploymentEmploymentStatus = stringToEmploymentStatus(
        currentEmployment.employmentStatus
      );
      const previousEmploymentEmploymentStatus = stringToEmploymentStatus(
        previousEmployment.employmentStatus
      );

      if (!currentAddressHousingStatus) {
        throw new Error('Unmapped housing status');
      } else if (!currentEmploymentEmploymentStatus) {
        throw new Error('Unmapped employment status');
      } else if (
        previousEmployment.employmentStatus &&
        !previousEmploymentEmploymentStatus
      ) {
        throw new Error('Unmapped employment status');
      }

      const parseDuration = (duration: string) => {
        return duration ? parseInt(duration) : undefined; // We need this check because parseInt('') is NaN and the field is optional
      };
      const parseAmount = (amount: string) => {
        return amount ? parseFloat(amount) : undefined; // We need this check because parseInt('') is NaN and the field is optional
      };
      const resp = await gqlMutationClient()({
        createCreditApplicationAndCreateUser: [
          {
            dealershipId: dealership?.dealership?.id ?? 'no-dealership-id',
            formData: {
              firstName: basicInformation.firstName,
              middleName: basicInformation.middleName,
              lastName: basicInformation.lastName,
              birthdate: basicInformation.dob,
              socialSecurityNumber: basicInformation.socialSecurityNumber,
              phoneNumber: parsePhoneNumberWithError(
                basicInformation.phoneNumber
              ).number,
              email: basicInformation.email,
              currentAddress: {
                street: currentAddress.street,
                apartmentDetails: currentAddress.apartmentDetails,
                city: currentAddress.city,
                zipCode: currentAddress.zipCode,
                state: currentAddress.state,
                durationYears: parseDuration(currentAddress.durationYears),
                durationMonths: parseDuration(currentAddress.durationMonths)
              },
              currentAddressHousingStatus,
              currentAddressMonthlyPayment: parseFloat(
                currentAddress.monthlyPayment
              ),
              previousAddress: previousAddress.street
                ? {
                    street: previousAddress.street,
                    apartmentDetails: previousAddress.apartmentDetails,
                    city: previousAddress.city,
                    zipCode: previousAddress.zipCode,
                    state: previousAddress.state,
                    durationYears: parseDuration(previousAddress.durationYears),
                    durationMonths: parseDuration(
                      previousAddress.durationMonths
                    )
                  }
                : undefined,
              currentEmployment: {
                ...currentEmployment,
                employerPhoneNumber:
                  currentEmployment.employerPhoneNumber.length > 0
                    ? parsePhoneNumberWithError(
                        currentEmployment.employerPhoneNumber
                      ).number
                    : undefined,
                employmentStatus: currentEmploymentEmploymentStatus,
                durationYears: parseDuration(currentEmployment.durationYears),
                durationMonths: parseDuration(currentEmployment.durationMonths),
                incomeAmount: parseAmount(currentEmployment.incomeAmount),
                incomeInterval: stringToIncomeInterval(
                  currentEmployment.incomeInterval
                ),
                otherIncomeAmount: parseAmount(
                  currentEmployment.otherIncomeAmount
                ),
                otherIncomeInterval: stringToIncomeInterval(
                  currentEmployment.otherIncomeInterval
                )
              },
              previousEmployment: previousEmploymentEmploymentStatus
                ? {
                    ...previousEmployment,
                    employerPhoneNumber:
                      previousEmployment.employerPhoneNumber.length > 0
                        ? parsePhoneNumberWithError(
                            previousEmployment.employerPhoneNumber
                          ).number
                        : undefined,
                    employmentStatus: previousEmploymentEmploymentStatus,
                    durationYears: parseDuration(
                      previousEmployment.durationYears
                    ),
                    durationMonths: parseDuration(
                      previousEmployment.durationMonths
                    ),
                    incomeAmount: parseAmount(previousEmployment.incomeAmount),
                    incomeInterval: stringToIncomeInterval(
                      previousEmployment.incomeInterval
                    ),
                    otherIncomeAmount: parseAmount(
                      previousEmployment.otherIncomeAmount
                    ),
                    otherIncomeInterval: stringToIncomeInterval(
                      previousEmployment.incomeInterval
                    )
                  }
                : undefined,
              fullName: consentData.fullName,
              signature: consentData.signature,
              dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
              deviceId: window.navigator.userAgent,
              ipAddress: publicData?.public?.ipAddress ?? 'unknown'
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationCreateCreditApplicationAndCreateUserSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        resp.createCreditApplicationAndCreateUser?.__typename === 'GraphQLError'
      ) {
        toast.error('An unexpected error happened');
        captureException(resp.createCreditApplicationAndCreateUser.message, {
          extra: {
            email: basicInformation.email
          }
        });
        console.error(resp.createCreditApplicationAndCreateUser.message);
      } else {
        setIsSubmitted(true);
      }
    } catch (e) {
      toast.error('An unexpected error happened');
      captureException(e, {
        extra: {
          currentAddressHousingStatus:
            currentAddressForm.state.values.housingStatus,
          currentEmploymentEmploymentStatus:
            currentEmploymentForm.state.values.employmentStatus
        }
      });
      console.error(e);
    }
  };
  const checkForExistingUser = async () => {
    try {
      setIsSubmitting(true);

      const isConsentValid = !consentForm.validateSync('submit').hasErrored;

      if (!isConsentValid) {
        toast.error(t('Please acknowledge and provide your signature'));
        return;
      }

      const basicInformation = basicInformationForm.state.values;
      const hasExistingUser = await hasExistingUserAction(
        basicInformation.email
      );

      if (!hasExistingUser) {
        await submitCreditApplication();
      } else {
        if (config.useCognito) {
          await cognitoRequestOtpAction(basicInformation.email);
        } else {
          await requestOtpExistingUserDEVAction(basicInformation.email);
        }

        setIsLoginModalOpen(true);
      }
    } catch (e) {
      toast.error('An unexpected error happened');
      captureException(e, {
        extra: { email: basicInformationForm.state.values }
      });
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <LoginModal
        isOpen={isLoginModalOpen}
        email={basicInformationForm.state.values.email}
        onLoginSuccess={submitCreditApplication}
      />

      <ConfirmationModal show={isSubmitted} />

      <div className="relative flex flex-col items-center bg-primary max-h-screen overflow-y-scroll">
        <div
          className={classNames(
            'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
            'w-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
          )}
        >
          <div
            className={classNames(
              'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
              'w-full relative flex'
            )}
          >
            <Button
              iconLeft={<ChevronLeft className="icon-tertiary" />}
              variant="GHOST"
              size="LARGE"
              onClick={handleGoBack}
              className={classNames('z-10', { hidden: step === 1 })}
            />

            <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center z-0">
              <ProgressBar totalSteps={3} currentStep={step} />
            </div>
          </div>

          <div
            className={classNames(
              'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
              'flex flex-col space-y-spacing-01',
              { hidden: step !== 1 }
            )}
          >
            <h2 className="heading-emphasized-02 text-primary">
              {t('Credit Application')}
            </h2>

            <h3 className="heading-03 text-tertiary">
              {t('Pre-Qualification and Credit Approval')}
            </h3>
          </div>

          <BasicInformationForm
            form={basicInformationForm}
            isSubmitting={isSubmitting}
            className={classNames({ hidden: step !== 1 })}
            environment="WEB"
          />

          <AddressForm
            form={currentAddressForm}
            isSubmitting={isSubmitting}
            className={classNames({ hidden: step !== 1 })}
            type="CURRENT"
            environment="WEB"
          />

          {hasFilledOutCurrentAddress && !atLeastTwoYearsAtCurrentAddress && (
            <Fragment>
              <div
                className={classNames(
                  'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
                  'flex flex-col space-y-spacing-01',
                  { hidden: step !== 1 }
                )}
              >
                <h2 className="heading-emphasized-02 text-primary">
                  {t('Previous Address')}
                </h2>

                <p className="body-01 text-secondary">
                  {t(
                    'Including the last two years of your housing is required.'
                  )}
                </p>
              </div>

              <AddressForm
                form={previousAddressForm}
                isSubmitting={isSubmitting}
                className={classNames({ hidden: step !== 1 })}
                type="PREVIOUS"
                environment="WEB"
              />
            </Fragment>
          )}

          <EmploymentForm
            form={currentEmploymentForm}
            isSubmitting={isSubmitting}
            className={classNames({ hidden: step !== 2 })}
            type="CURRENT"
          />

          {hasFilledOutCurrentEmployment &&
            !atLeastTwoYearsAtCurrentEmployment && (
              <Fragment>
                <div
                  className={classNames(
                    'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
                    'flex flex-col space-y-spacing-01',
                    { hidden: step !== 2 }
                  )}
                >
                  <h2 className="heading-emphasized-02 text-primary">
                    {t('Previous Employment')}
                  </h2>

                  <p className="body-01 text-secondary">
                    {t(
                      'Including the last two years of your employment is required.'
                    )}
                  </p>
                </div>

                <EmploymentForm
                  form={previousEmploymentForm}
                  isSubmitting={isSubmitting}
                  className={classNames({ hidden: step !== 2 })}
                  type="PREVIOUS"
                />
              </Fragment>
            )}

          <CreditApplicationConsentForm
            dealershipSlug={dealershipSlug}
            dealershipName={dealership?.dealership?.name ?? 'Dealership'}
            form={consentForm}
            className={classNames({ hidden: step !== 3 })}
            isSubmitting={isSubmitting}
            type="WEB"
          />

          <div
            className={classNames(
              'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
              'flex flex-row justify-end pt-spacing-04'
            )}
          >
            <Button
              label={step === 3 ? t('Submit') : t('Next')}
              onClick={step === 3 ? checkForExistingUser : handleGoToNext}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreditApplicationPage;
