import { Add, Remove } from '@mui/icons-material';
import { FC, Fragment, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

const NumberInput: FC<{
  /**
   * Name of the input
   */
  name?: string;

  value: string;
  label: string;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  name,
  value,
  prefix,
  suffix,
  decimalScale = 2,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK',
  dataTestId
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const handleChange = (updatedValue: string) => {
    setIsTyping(true);
    onChange(updatedValue);
  };
  const increaseValue = () => {
    setIsTyping(true);
    onChange(
      (
        (value !== 'NaN' && value.length > 0 ? parseFloat(value) : 0) + 1
      ).toString()
    );
  };
  const decreaseValue = () => {
    setIsTyping(true);
    onChange(
      (
        (value !== 'NaN' && value.length > 0 ? parseFloat(value) : 0) - 1
      ).toString()
    );
  };

  return (
    <InputBase
      dataTestId={dataTestId}
      inputComponent={
        <Fragment>
          <NumericFormat
            name={name}
            prefix={prefix}
            value={value}
            onValueChange={({ value }) => {
              handleChange(value);
            }}
            placeholder={placeholder}
            aria-placeholder={placeholder}
            disabled={disabled}
            decimalScale={decimalScale}
            thousandSeparator=","
            suffix={suffix}
            onBlur={() => {
              setIsTyping(false);
              onBlur?.();
            }}
            onFocus={() => {
              setIsTyping(true);
            }}
            className="body-01 w-full px-spacing-02 py-[6px] text-primary outline-none placeholder:text-tertiary disabled:text-tertiary bg-transparent"
          />

          <div
            className="flex size-10 items-center justify-center icon-secondary"
            onClick={decreaseValue}
          >
            <Remove className="text-[16px]" fontSize="inherit" />
          </div>

          <div className="w-0 h-4 border border-secondary" />

          <div
            className="flex size-10 items-center justify-center icon-secondary"
            onClick={increaseValue}
          >
            <Add className="text-[16px]" fontSize="inherit" />
          </div>
        </Fragment>
      }
      label={label}
      assistiveMessage={assistiveMessage}
      required={required}
      disabled={disabled}
      isBeingUpdated={isTyping}
      errorMessage={errorMessage}
      backgroundType={backgroundType}
      className={className}
    />
  );
};

export default NumberInput;
