import { Wifi, WifiOff } from '@mui/icons-material';
import { ToastContentProps } from 'react-toastify';

/**
 * This type is a custom payload for the toast component
 * Any data or callbacks to the custom toast can be passed through here
 * This implementation is based for react-toast v10, newer version might have different way managing state
 */
export type OfflineBadgeData = {
  isOnline: boolean;
  onRetry?: () => Promise<void>;
};

/**
 * An implementation of React Toastify custom badge
 * This component used to display network connection status
 */
export default function OfflineBadge({
  data
}: ToastContentProps<OfflineBadgeData>) {
  return (
    <div className="flex flex-row justify-between items-center w-full bg-white rounded-md relative">
      <div className="flex flex-col">
        <h3 className="label-02 font-semibold">
          You are {data.isOnline ? 'online' : 'offline'}
        </h3>
        {!data.isOnline && (
          <p className="body-03">Check your connection and retry.</p>
        )}
        {data.isOnline && <p className="body-03">Connection restored!</p>}
      </div>

      <div className="flex items-center justify-center">
        {!data.isOnline && (
          <div
            className="flex flex-col cursor-pointer items-center justify-center gap-1 hover:bg-gray-100 active:bg-gray-200 p-1 rounded-md"
            onClick={data.onRetry}
          >
            <WifiOff
              fontSize="large"
              className={'text-red-500 animate-pulse'}
            />
            <span className="body-03 text-red-500">RETRY</span>
          </div>
        )}

        {data.isOnline && (
          <Wifi fontSize="large" className={'text-green-500 animate-pulse'} />
        )}
      </div>
    </div>
  );
}
